




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input, Message } from 'element-ui';
import Auth from '@/sdk/common/Auth';
import sdk from '@/sdk/InstoreSdk';
import 'element-ui/lib/theme-chalk/index.css';

import Cookie from 'js-cookie';
@Component
export default class InsLogin extends Vue {
  imgsrc: string = '@/assets/Images/void-cart.png';
  isShow: boolean = false;
  lang:string[] = ['E', 'C', 'S'];

  checkEmail (rule, value, callback) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (!value) {
      callback(new Error(this.$t('Login.EnterEmail')as string));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('Login.CorrectEmail')as string));
      }
    }, 100);
  }
  private loginForm = {
    user: '',
    password: ''
  }
  isIe = true;
  created () {
    if (window.navigator.userAgent.indexOf('Trident') !== -1) this.isIe = true;
    else this.isIe = false;
  }
  // 表单验证规则
  private rules = {
    user: [
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' }
    ]
  }
  // get rules () {
  //   return {
  //     user: [{ required: true, validator: this.checkEmail, trigger: 'blur' }],
  //     password: [
  //       { required: true, message: this.$t('Login.LoginPwd'), trigger: 'blur' }
  //     ]

  //   };
  // }

  // 显示登录框
  toggleDialog () {
    this.isShow = !this.isShow;
  }

  // 关闭登录框
  closeDialog () {
    this.isShow = false;
  }

  // 提交表单
  submitForm () {
    (this.$refs.loginForm as ElForm).validate((valid) => {
      if (valid) {
        this.doLogin();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  // 重置表单
  resetForm () {
    (this.$refs.loginForm as ElForm).resetFields();
  }

  // 登陆
  doLogin () {
    let _this = this;
    sdk.api.member.login(this.loginForm.user, this.loginForm.password, true).then(
      function (data) {
        console.log(_this.loginForm, '_this.loginForm');
        _this.resetForm();
        _this.$store.dispatch('doLogin');
        _this.isShow = false;
        _this.getShopCart();
        // _this.getProfile();
        window.location.href = ('/account/memberCentral');
      },
      function (data) {
        _this.$message({
          message: data.Message,
          type: 'error'
        });
      }
    );
  }

  // 登出
  Logout () {
    let _this = this;
    sdk.api.member.logout().then(
      function (data) {
        console.log(data, '登出系统');
        _this.$store.dispatch('Logout');
        // storage.remove('access_token');
        Cookie.remove('access_token');
        Auth.GetToken();
      },
      function (data) {
        Message({
          message: data,
          type: 'error'
        });
      }
    );
  }

  // getShopCart () {
  //   this.$Api.shoppingCart.LoadData().then((result) => {
  //     console.log(result, '购物车信息');
  //     // this.$store.dispatch('setCartItems', result.Items);
  //     // this.$store.dispatch('setShopCartNum', result.Qty);
  //     this.$store.dispatch('setShopCart', result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //     Message({
  //       message: '获取购物车失败！',
  //       type: 'error'
  //     });
  //   });
  // }
  getShopCart () {
    this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
  }
  // 获取会员信息
  getAccount () {
    let _this = this;

    sdk.api.member.getAccount().then(
      function (data) {
        if (data.Logined) {
          _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
          _this.$store.dispatch('doLogin');
        } else {
          _this.$store.dispatch('Logout', true);
        }
      },
      function (data) {
        Message({
          message: data,
          type: 'error'
        });
      }
    );
  }

  get isLogin () {
    return this.$store.state.isLogin;
  }

  get user () {
    return this.$store.state.user;
  }

  mounted () {
    this.getAccount();
  }
  fbLogin () {
    window['FB'].login(function (response) {
      window['checkLoginState']();
    }, { scope: 'email' });
  }
}
